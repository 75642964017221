import {  Modal } from 'antd';
import { useEffect, useState } from 'react';
import './index.scss'

const Dialog = (props)=>{
    const {title,vis,children,operDialogFunc,footer,width,closable} = props
    const [isModalOpen, setIsModalOpen] = useState(false);

    const handleOk = () => {
        operDialogFunc(false);
    };
    const handleCancel = () => {
        operDialogFunc(false);
    };

    useEffect(()=>{
        setIsModalOpen(vis)
    })
  


    return(
        <>
          <Modal 
            centered 
            className='myDialog' 
            title={title} 
            footer={footer}
            width={width?width:'376px'}
            closable={!closable?closable:true}
            open={isModalOpen} 
            onOk={handleOk} 
            onCancel={handleCancel}>
            {children}
          </Modal>
        </>
    )
}

export default Dialog