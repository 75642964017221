import {  Tour,Button, Form,Input,message,Space,Switch } from 'antd';
import { useDispatch,useSelector } from 'react-redux';
import { useEffect, useRef, useState } from 'react';
import Dialog from '@/components/Dialog'
import api from '@/api'
import { fetchUserInfo } from '@/store/modules/user'
import { tool,setGuide, getGuide } from "@/utils"
import VerifySlideFixed from "@/components/Verify/verifySlideFixed";

const UserInfo = ()=>{
    const [open, setOpen] = useState(false);

    const ref1 = useRef(null);
    const ref2 = useRef(null);
    

    const steps = [
        {
          title: '第一步',
          description: '点击实名认证',
          target: () => ref1.current,
        },
        {
          title: '第二步',
          description: '复制专属交易链接至第三方平台',
          target: () => ref2.current,
        }
    ];

    const onFinishTour= ()=>{
        setGuide(true)
        api.user.setInit.get()
    }


    const onChange = (val) => {
        api.user.changeAuto.get(val).then(res=>{
            if(res.code == 0){
                message.success("操作成功")
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };
    const userInfo = useSelector(state=>state.user.userInfo)
    const [ form ] = Form.useForm();
    const [tradeUrl, setTradeUrl] = useState("");
    const dispatch = useDispatch()
    const [time,setTime] = useState(60)
    const [flag,setFlag] = useState(false)
    const [slideShow,setSlideShow] = useState(false)
    const [countDown,setCountDown] = useState(0)

    useEffect(()=>{
        setTradeUrlInfo()
        let guide = getGuide()
        if(guide == 'false'){
            setOpen(true)
        }else{
            setOpen(false)
        }
    },[])

    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditName, setIsEditName] = useState(false);
    const [isEditPhone, setIsEditPhone] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
    const editName = ()=>{
        setIsEditName(true)
    }
    const editPhone = ()=>{
        setIsEditPhone(true)
    }

    const operDialogFunc = (flag)=>{
        setIsModalOpen(flag)
    }
    const operNameFunc = (flag)=>{
        setIsEditName(flag)
    }
    const operPhoneFunc = (flag)=>{
        setIsEditPhone(flag)
    }

    const onFinish = (values) => {
        api.user.real.post(values).then(res=>{
            if(res.code == 0){
                message.success("认证成功");
                setIsModalOpen(false)
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };
    const onNameFinish = (values) => {
        api.user.saveName.post(values).then(res=>{
            if(res.code == 0){
                message.success("修改成功");
                setIsEditName(false)
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };
    const onPhoneFinish = (values) => {
        api.user.real.post(values).then(res=>{
            if(res.code == 0){
                message.success("修改成功");
                setIsEditPhone(false)
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };

    const apply = ()=>{
        api.user.apply.get().then(res=>{
            if(res.code == 0){
                message.success('申请成功')
                setTradeUrlInfo()
            }else{
                message.error(res.msg)
            }
        })
    }  
  
    
    const verifyPointFixedChild = (data)=>{
        setSlideShow(data)
    }

    const sendSms = ()=>{
        if(flag) return;
        var value = form.getFieldValue()
        if(!value.phone){
            message.error('请输入正确的手机号')
            return;
        }
        if(value.phone.length !=11){
            message.error('请输入正确的手机号')
            return;
        }
        setFlag(true)
        setSlideShow(true)
    }
    const onsuccess =(data)=>{
        setSlideShow(false)
        var data= {
            phone:form.getFieldValue().phone,
            codeType:'login',
            captchaVerification:data.captchaVerification
        }
        api.common.sendSms.post(data).then(res=>{
            if(res.code == 0){
                message.success('短信已发送，请注意查收！')
                const inter = setInterval(()=>{
                    if(time<=0){
                        setTime(60)
                        clearInterval(inter)
                        setFlag(false)
                    }else{
                        let tiem = time - 1;
                        setTime(tiem)
                    }
                },1000)
            }else{
                message.error(res.msg)
                setFlag(false)
            }
        })
    }

    const setTradeUrlInfo = ()=>{
        api.user.getTradeUrl.get().then(res=>{
            if(res.code == 0){
                setTradeUrl(res.data.tradeUrl)
                let nowTime = Date.parse(new Date())
                let endTime = Date.parse(res.data.expireTime)
                let time = (endTime - nowTime)/1000
                setCountDown(time)
                const inter = setInterval(()=>{
                    if(time<=0){
                        clearInterval(inter)
                        setTradeUrlInfo()
                    }else{
                        time--;
                        setCountDown(time)
                    }
                },1000)

            }else{
                setTradeUrl("")
                setCountDown(0)
            }
        })
    }

    const timeFormat = (time)=>{

        let hour = parseInt(time/60/60) 
        let min = parseInt(time/60%60)
        let sec = parseInt(time%60)

        return (hour>=10? hour:'0'+hour) + ':' + (min>=10? min:'0'+min) + ':' + (sec>=10? sec:'0'+sec)
    }


    return (
        <div className="userInfo">
            <Form
                name="basic"
                labelCol={{
                    xl:4,
                    xs:5
                }}
                wrapperCol={{
                    xl:8,
                    xs:19
                }}
                style={{
                    maxWidth: 600,
                }}
                initialValues={{
                    remember: true,
                }}
                autoComplete="off"
            >
                <Form.Item
                    label="我的昵称"
                    name="money"
                >   
                    <p className='info-item-m'> <span>{userInfo.nickname}</span> <span className='input-btn' onClick={editName}> <i className='edit'></i> 修改 </span> </p>
                </Form.Item>
            
                <Form.Item
                    label="用户ID"
                    name="account"
                >
                     <p className='info-item-m'>{userInfo.userId}</p>
                </Form.Item>

                <Form.Item
                    label="手机号码"
                    name="name"
                >
                    <p className='info-item-m'>{userInfo.phone}</p>
                </Form.Item>

                <Form.Item
                    label="实名认证"
                    name="name"
                >
                    <p className='info-item-m'> <span>{userInfo.isRealName?'已实名':'未实名'}</span> {!userInfo.isRealName && <span className='input-btn' onClick={showModal} ref={ref1}> <i className='renz'></i> 认证 </span>}</p>
                </Form.Item>

                <Form.Item
                    label="邀请码"
                    name="name"
                >
                    <p className='info-item-m'>{userInfo.promoteCode}</p>
                </Form.Item>

                <Form.Item
                    label="专属交易链接"
                    name="name"
                >   
                    <p className='info-item-m'> <span> { tradeUrl?tradeUrl:'--' }</span>  {tradeUrl?<span className='input-btn' onClick={()=>tool.copy(tradeUrl)}  ref={ref2}> <i className='link'></i> 复制 </span>:<span className='input-btn' onClick={()=>apply()} ref={ref2}> <i className='link'></i> 获取专属交易链接 </span>}</p>
                </Form.Item>

                <Form.Item
                    label="交易链接有效期"
                    name="name"
                >
                    <p className='info-item-m'>{ timeFormat(countDown) }</p>
                </Form.Item>

                <Form.Item
                    label="自动收货"
                    name="name"
                >   
                    <p className='info-item-m'>{userInfo.isAutoReceive?'已开启':'已关闭'}  <Switch checked={ userInfo.isAutoReceive } onChange={onChange} /></p>
                </Form.Item>
            </Form>
            <Tour open={open} style={{width:'270px'}}   onClose={() => setOpen(false)} steps={steps} onFinish={ onFinishTour } />
            <Dialog 
                title="实名认证" 
                footer = {<></>}
                vis={isModalOpen} 
                operDialogFunc={operDialogFunc}>
                <Form name="realForm"  autoComplete="off" onFinish={ onFinish } 
                    labelCol={{
                        xl: 6,
                        xs:6
                    }}
                    wrapperCol={{
                        xl: 18,
                        xs:18
                    }}>
                    <Form.Item label="真实姓名" name="idcName">
                        <Input />
                    </Form.Item>
                    <Form.Item label="身份证号" name="idcNo">
                        <Input />
                    </Form.Item>
                    <div className="footer">
                        <Button type='primary' htmlType='submit' className='button'>立即实名</Button>
                    </div>
                </Form>
            </Dialog>

            <Dialog 
                title="修改用户名" 
                footer = {<></>}
                vis={isEditName} 
                operDialogFunc={operNameFunc}>
                <Form name="realForm"  autoComplete="off" onFinish={ onNameFinish }
                    labelCol={{
                        xl: 6,
                        xs:6
                    }}
                    wrapperCol={{
                        xl: 18,
                        xs:18
                    }}>
                    <Form.Item label="用户名" name="newNickname">
                        <Input defaultValue={ userInfo.nickname } />
                    </Form.Item>
                    <div className="footer">
                        <Button type='primary' htmlType='submit' className='button'>立即修改</Button>
                    </div>
                </Form>
            </Dialog>

            <Dialog 
                title="修改手机号" 
                footer = {<></>}
                vis={isEditPhone} 
                operDialogFunc={operPhoneFunc}>
                <Form name="realForm"  autoComplete="off" onFinish={ onPhoneFinish } form = { form }
                    labelCol={{
                        xl: 6,
                        xs:6
                    }}
                    wrapperCol={{
                        xl: 18,
                        xs:18
                    }}>
                    <Form.Item label="手机号" name="phone">
                        <Input placeholder='请输入新手机号'/>
                    </Form.Item>
                    <Form.Item label="验证码" name="idcNo">
                        <Space.Compact >
                            <Input placeholder="请输入验证码"/>
                            <Button type="link" onClick={sendSms} disabled={flag}> {flag && (<> {time}s </>)}  获取验证码  </Button>
                            <VerifySlideFixed isSlideShow={slideShow} verifyPointFixedChild={verifyPointFixedChild}  verifyPointFixedSuccess ={ onsuccess }/> 
                        </Space.Compact>
                    </Form.Item>
                    <div className="footer">
                        <Button type='primary' htmlType='submit' className='button'>立即修改</Button>
                    </div>
                </Form>
            </Dialog>
        </div>
    )
}

export default UserInfo