import './index.scss'
import { useParams } from "react-router-dom"
import api from '@/api'
import { useEffect, useState } from 'react'
import { tool } from '@/utils'

const Detail = ()=>{
    const params =  useParams()
    const id = params.id
    const [list,setList] = useState([])

    const [orderInfo,setOrderInfo] = useState({});


    const getDetail =async (pageNum) => {
        let params = {
            pageSize:5,
            pageNum:pageNum,
            orderNo:id
        }
        let res = await api.order.detail.post(params);
        setList(res.data)
    }

    const getInfo = async ()=>{
        let res = await api.order.info.get(id);
        if(res.code == 0){
            setOrderInfo(res.data)
        }
    }

    useEffect(()=>{
        getDetail(1)
        getInfo()
    },[])

    return(
        <div className="order-detail">
            <div className="order-top">
                {
                    list.map((item)=>(
                        <div className='weapon-item' key={item.itemImg}>
                            <div className="image-wrap">
                                <img src={item.itemImg} alt={item.itemName}></img>
                            </div>
                            <p>{item.itemName}</p>
                        </div>
                    ))
                }
                
            </div>
            <div className="order-info">
                { orderInfo.realExchangePrice &&
                    <div className="item">
                        <span className="label">回收价格</span>
                        <span className="value">{orderInfo.realExchangePrice}</span>
                    </div>
                }
                <div className="item">
                    <span className="label">创建时间</span>
                    <span className="value">{orderInfo.createAt}</span>
                </div>
                <div className="item">
                    <span className="label">订单编号</span>
                    <span className="value"><i className="copy" onClick={()=>tool.copy(id)}></i> { id }</span>
                </div>
                { orderInfo.orderRemark &&
                    <div className="item">
                        <span className="label">订单备注</span>
                        <span className="value">{orderInfo.orderRemark}</span>
                    </div>
                }
                
                <div className="item">
                    <span className="label">订单状态</span>
                    <span className="value"> 
                            { orderInfo.orderStatus==0 && <span style={{color:'#E98E1B'}}> · 待审核 </span> }
                            { orderInfo.orderStatus==1 && <span style={{color:'#7F68FF'}}> · 待处理 </span> }
                            { orderInfo.orderStatus==2 && <span style={{color:'#DE1F0F'}}> · 已驳回 </span> }
                            { orderInfo.orderStatus==3 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                            { orderInfo.orderStatus==10 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                            { orderInfo.orderStatus==4 && <span style={{color:'#DE1F0F'}}> · 已拒绝 </span> }
                            { orderInfo.orderStatus==11 && <span style={{color:'#DE1F0F'}}> · 已拒绝 </span> }
                            { orderInfo.orderStatus==5 && <span style={{color:'#DE1F0F'}}> · 报价错误 </span> }
                            { orderInfo.orderStatus==6 && <span style={{color:'#DE1F0F'}}> · 卖家取消 </span> }
                            { orderInfo.orderStatus==7 && <span style={{color:'#E98E1B'}}> · 交易暂挂 </span> }
                            { orderInfo.orderStatus==8 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                     </span>
                </div>
            </div>
        </div>
    )
}

export default Detail