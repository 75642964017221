import { Input,Button,Pagination,message,Drawer, Space, Empty, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router-dom'
import Dialog from "@/components/Dialog";
import './index.scss'
import api from '@/api'
import { useEffect, useState } from 'react';

const Mpage = ()=>{
    const navigate = useNavigate();
    const [open, setOpen] = useState(false);
    const [orderNo,setOrderNo] = useState('')
    const [orderList,setOrderList] = useState([])
    const [totalCount,setTotalCount] = useState([])
    const [status,setStatus] = useState("")
    const [flag,setFlag] = useState(true)
    const [title,setTitle] = useState('')
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };
    const pageSize = 5;
    const [showTip,setShoTip] = useState(false)
    const [tipTitle,setTipTitle] = useState('')
    const [tipContent,setTipContent] = useState('')
    const [tipFooter,setTipFooter] = useState('')

    const operTipFunc = (flag)=>{
        setShoTip(flag)
    }
    async function getData(page){
        let sendStatus =[]
        if(status == 3){
            sendStatus=[3,8,10]
        }else if(status == 4){
            sendStatus = [4,11]
        }else if(status===0 || status){
            sendStatus.push(status)
        }

        let params = {
            pageNum:page,
            pageSize:pageSize,
            status:sendStatus,
            orderNo:orderNo
        }
        if(status === '' || status==='all'){
            params.status = [0,1,2,3,4,5,6,7,8,9,10,11];
        }
        if(!orderNo){
           delete params.orderNo;
        }
        var res = await api.order.list.post(params)
        if(res.code == 0){
            setOrderList(res.data)
            setTotalCount(res.totalCount)
        }
        getSum(params)
    }

    useEffect(()=>{
        getData(1)
    },[])

    const onchange = (val)=>{
        getData(val)
    }

    const showTips = (record,type)=>{
        if(type == 'receipt'){
          setTipTitle('是否确认接受该订单结算金额')
          setTipContent('￥'+record.realExchangePrice)
          setTipFooter(
            <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                <Button onClick={()=>setShoTip(false)}>取 消</Button>
                <Button type='primary' onClick={()=>operate(record,type)}>确 认</Button>
            </div>
          )
        }else{
          setTipTitle('温馨提示')
          setTipContent('是否确认取消报价，多次取消会禁用账号')
          setTipFooter(
            <div style={{display:'flex',justifyContent:'center',gap:'20px'}}>
                <Button onClick={()=>setShoTip(false)}>取 消</Button>
                <Button type='primary' onClick={()=>operate(record,type)}>确 认</Button>
            </div>
          )
        }
        setShoTip(true)
      }


    const operate = (record,type)=>{
        if(!flag){
            message.error('操作进行中，请勿重复点击~~')
            return;
          }
          setFlag(false)
          let params = {
              orderNo:record.orderNo,
              type:type
          }
          api.order.operate.post(params).then(res=>{
              if(res.code == 0){
                  message.success('操作成功')
              }else{
                  message.error(res.msg)
              }
              if(type=='receipt'){
                record.orderStatus = 3
              }else{
                record.orderStatus = 4
              }
              setFlag(true)
              setShoTip(false)
          })
    }

    const upSearch = ()=>{
        getData(1)
        setOpen(false);
    }

    const reset = ()=>{
        setStatus('all')
        setOrderNo('')
    }

    async function getSum(filterForm){
        let res = await api.order.statistics.post(filterForm)
        if(res.code == 0){
            var title1 =  <div className='statistics' size="middle" style={{fontSize:'12px'}}> <span>总订单笔数 ：{ res.data.totalOrderCount }</span>  <span>总订单金额 ：¥ { res.data.totalOrderAmount }</span>  </div> 
            setTitle(title1)
        }
    }
    return(
        <div className="m-order-wrap">
            <div className="top-wrap">
                
                <Input prefix={<SearchOutlined/>} value={orderNo} onChange={(e)=>setOrderNo(e.target.value)}  placeholder="请输入订单号"  onPressEnter={upSearch}/>
                <i className='filter' onClick={()=>showDrawer()}></i>
                <Drawer
                    title=" 订单状态"
                    placement={'right'}
                    closable={false}
                    onClose={onClose}
                    width={'78vw'}
                    open={open}
                    className="right-menu"
                    footer={
                        <>  
                            <div className='right-footer'>
                                <Button className='btn' ghost onClick={()=>reset()}>重置</Button>
                                <Button className='btn' type="primary" onClick={ ()=>upSearch() }>确认</Button>
                            </div>
                        </>
                    }
                >   
                    <div className='left-btns'>
                        <Button className='btn' ghost={status!=='all'} onClick={()=>setStatus('all')} >全部</Button>
                        <Button className='btn' ghost={status!==0} onClick={()=>setStatus(0)} >待审核</Button>
                        <Button className='btn' ghost={status!=1} onClick={()=>setStatus(1)} >待处理</Button>
                        <Button className='btn' ghost={status!=2} onClick={()=>setStatus(2)} >已驳回</Button>
                        <Button className='btn' ghost={status!=3} onClick={()=>setStatus(3)} >已完成</Button>
                        <Button className='btn' ghost={status!=4} onClick={()=>setStatus(4)} >已拒绝</Button>
                        <Button className='btn' ghost={status!=5} onClick={()=>setStatus(5)} >报价错误</Button>
                        <Button className='btn' ghost={status!=6} onClick={()=>setStatus(6)} >卖家取消</Button>
                        <Button className='btn' ghost={status!=7} onClick={()=>setStatus(7)} >交易暂挂</Button>
                    </div>
                </Drawer>
            </div>
            {title}
            <div className='orderlist'>
                {
                    orderList.map((item)=>(
                        <div className='item' key={item.orderNo} >
                            <div className='top'>
                                <div className='time'>
                                    <i className='timei'></i>
                                    { item.createAt }
                                </div>
                                { item.orderStatus==0 && <span style={{color:'#E98E1B'}}> · 待审核 </span> }
                                { item.orderStatus==1 && <span style={{color:'#7F68FF'}}> · 待处理 </span> }
                                { item.orderStatus==2 && <span style={{color:'#DE1F0F'}}> · 已驳回 </span> }
                                { item.orderStatus==3 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                                { item.orderStatus==10 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                                { item.orderStatus==4 && <span style={{color:'#DE1F0F'}}> · 已拒绝 </span> }
                                { item.orderStatus==11 && <span style={{color:'#DE1F0F'}}> · 已拒绝 </span> }
                                { item.orderStatus==5 && <span style={{color:'#DE1F0F'}}> · 报价错误 </span> }
                                { item.orderStatus==6 && <span style={{color:'#DE1F0F'}}> · 卖家取消 </span> }
                                { item.orderStatus==7 && <span style={{color:'#E98E1B'}}> · 交易暂挂 </span> }
                                { item.orderStatus==8 && <span style={{color:'#20BB19'}}> · 已完成 </span> }
                            </div>
                            <div className='content-wrap'>
                                <div className='info' onClick={()=>navigate('/order/detail/'+item.orderNo+`?status=${item.orderStatus}&createAt=${item.createAt}`)}>
                                    <div className='image-wrap'>
                                        <img src='https://file.v5item.com/items/1eaac6dadb0f8cae0d73391de29c9c28.jpg' alt="饰品图"></img>
                                    </div>
                                    <div className='weapon-info'>
                                        <p>预估回收价格： <span>￥{ item.preExchangePrice }</span> </p>
                                        <p>实际回收价格： <span style={{color:'#7F68FF'}}>￥ {item.realExchangePrice} </span> </p>
                                    </div>
                                </div>
                                <div className='right'>
                                    {
                                        item.orderStatus == 1 &&
                                        <div className='buttons'>
                                            <Button onClick={()=>showTips(item,'receipt')}>接受报价</Button>
                                            <Button ghost onClick={()=>showTips(item,'reject')}>取消报价</Button>
                                        </div>
                                    }
                                    
                                </div>
                            </div>
                        </div>
                    ))
                }
                { orderList.length<=0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}

                { totalCount>pageSize &&
                    <Pagination defaultCurrent={1} total={totalCount} pageSize={pageSize} onChange={onchange} />
                }

                <Dialog
                    title={ tipTitle }
                    footer = {<></>}
                    vis={ showTip }
                    operDialogFunc={operTipFunc}
                    >
                    <p style={{textAlign:'center'}}> { tipContent }</p>
                    {tipFooter}
                </Dialog>
            </div>
        </div>
    )
}

export default Mpage;