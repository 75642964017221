import config from "@/config"
import http from "@/utils/request"

export default{
    login:{
        BySms:{
            url:`${config.API_URL}/user/loginBySMS`,
            name:"用户验证码登录",
            post: async function(data,config={}){
                return await http.post(this.url,data,config)
            }
        },
        ByPwd:{
            url:`${config.API_URL}/user/login`,
            name:"用户账号密码登录",
            post: async function(data,config={}){
                return await http.post(this.url,data,config)
            }
        }
    },
    register:{
        url:`${config.API_URL}/user/register`,
        name:"用户注册",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    info:{
        url:`${config.API_URL}/user/checkUser`,
        name:"获取用户信息",
        get: async function(){
            return await http.get(this.url)
        }
    },
    forget:{
        url:`${config.API_URL}/user/forgetPassword`,
        name:"忘记密码接口",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    getTradeUrl:{
        url:`${config.API_URL}/user/getTradeUrl`,
        name:"获取用户交易链接",
        get: async function(){
            return await http.get(this.url)
        }
    },
    real:{
        url:`${config.API_URL}/user/authentication`,
        name:"实名认证接口",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    apply:{
        url:`${config.API_URL}/user/applyTradeUrl/`,
        name:"申请专属交易链接",
        get: async function(storeId=0){
            return await http.get(this.url+storeId)
        }
    },
    saveName:{
        url:`${config.API_URL}/user/setNickname`,
        name:"修改用户昵称",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    changeAuto:{
        url:`${config.API_URL}/user/setAutoReceiveTag/`,
        name:"设置自动接收报价状态",
        get: async function(status){
            return await http.get(this.url+status)
        }
    },
    setInit:{
        url:`${config.API_URL}/user/setUserIsInit`,
        name:"设置用户新手指导流程已完成",
        get: async function(){
            return await http.get(this.url)
        }
    }
}