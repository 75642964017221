import Wpage from "./Wpage"
import Mpage from "./Mpage";
import { useState,useEffect } from "react";

const Order = () =>{
  const [screenWidth,setScreenWidth] = useState();
  useEffect(()=>{
      const updateScreenSize = ()=>{
          setScreenWidth(window.innerWidth)
      }

      window.addEventListener('resize',updateScreenSize);
      updateScreenSize();

      return ()=> window.removeEventListener('resize',updateScreenSize)
  },[])

  const getTable = ()=>{
      if(screenWidth <= 790){
          return <Mpage />
      }else{
          return <Wpage />
      }
  }

    return(
        <>
          {getTable()}
        </>
    )
}
export default Order