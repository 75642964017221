import config from "@/config"
import http from "@/utils/request"

export default{
    list:{
        url:`${config.API_URL}/user/getOrderInfoListByUser`,
        name:"回收订单列表",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    operate:{
        url:`${config.API_URL}/user/operateOrder`,
        name:"处理回收订单",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    detail:{
        url:`${config.API_URL}/user/getOrderItemListDetail`,
        name:"查看订单饰品详情",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    statistics:{
        url:`${config.API_URL}/user/getSumUserOrderInfo`,
        name:"回收订单统计",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    info:{
        url:`${config.API_URL}/user/getOrderDetail/`,
        name:"查看具体订单信息",
        get: async function(orderNo){
            return await http.get(this.url+orderNo)
        }
    }
}