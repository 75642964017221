import { useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import './index.css'

const Footer = ()=>{
    const siteInfo = useSelector(state=>state.site.siteInfo)
    const navigate = useNavigate();
    return (
        <div className="Footer">
            <div className='content'>
                <div className="top">
                    <div className='menu'>
                        <div className='item'><a href='/protocol/yhxy' >用户协议</a></div>
                        <div className='item'><a href='/protocol/ysxy' >隐私协议</a></div>
                        <div className='item'><a href='/protocol/about'>关于我们</a></div>
                        <div className='item'><a href='/protocol/fwxy'>服务协议</a></div>
                    </div>
                    <p className='copyright'>{ siteInfo.copyRight }</p>
                </div>
                <div className='line'></div>
                <p className='copy'>{ siteInfo.copyRight } </p>
                <p>Copyright 2024-2026 .AII Rights Reserved</p>
                <p>地址：湖南湘江新区麓谷街道延龙路72号长沙市云海通讯设备有限公司3栋703室</p>
                <p>健康游戏公告：抵制不良游戏 拒绝盗版游戏 注意自我保护 谨防受骗上当 适度游戏益脑 沉迷游戏伤身 合理安排时间 享受健康生活</p>
            </div>
        </div>
    )
}

export default Footer;