import { Button, Input, Pagination, Drawer, Empty, message } from "antd";
import Notic from "../Board/components/Notic";
import './index.scss'
import api from '@/api'
import { useEffect, useState } from "react";
import { useNavigate } from 'react-router-dom'
import { SearchOutlined } from '@ant-design/icons';
import { tool } from "@/utils";
import search from './img/search.png'


const ReceiveList = ()=>{
    const [typeList,setTypeList] = useState([])
    const [itemType,setItemType] = useState('hot')
    const [itemName,setItemName] = useState('')
    const [itemList,setItemList] = useState([])
    const [open, setOpen] = useState(false);
    const [total,setTotal] = useState(0)
    const pageSize = 15
    const navigate = useNavigate();

    async function  getType(){
        let res = await api.receive.type.list.get();
        if(res.code == 0){
            setTypeList(res.data)
        }
    }
    const showDrawer = () => {
        setOpen(true);
    };
    const onClose = () => {
        setOpen(false);
    };

    async function getList(pageNum){
        
        let params={
            itemType:itemType,
            itemName:itemName,
            pageNum:pageNum,
            pageSize:pageSize
        }
        if(params.itemType == 'hot'){
            params.itemType = '';
            params.isHot = 1
        }else{
            params.isHot = 0;
        }
        let res = await api.receive.list.post(params);
        if(res.code == 0){
            setItemList(res.data)
            setTotal(res.totalCount)
        }
    }

    const reset = ()=>{
        setItemType('')
        setItemName('')
        setOpen(false)
    }

    const getTradeUrl = ()=>{
        api.user.getTradeUrl.get().then(res=>{
            if(res.code == 0){
                tool.copy(res.data.tradeUrl)
            }else{
                message.error(res.msg)
                setTimeout(()=>{
                    navigate('/center')
                },1000)
            }
        })
    }

    useEffect(()=>{
        getList(1);
    },[itemType,itemName])

    return(
        <div className="receivelist-wrap">
            <Notic />
            { window.innerWidth >790 &&
             <div className="topTitle" style={{marginTop:'16px'}}>总件数：{total}</div>
            }
            <div className="receivelist-content">
                <div className="filter-wrap">
                    { window.innerWidth >790 &&
                        <div className="left-wrap">
                            <div className="types">
                                {/* {typeList.map((item)=>(
                                    <div className="item active" key={item.id}>{item.itemTypeName}</div>
                                ))} */}
                                <div className={itemType=='hot'?'item active':'item'} onClick={()=>setItemType('hot')}>热门</div>
                                <div className={itemType=='csgo_type_knife'?'item active':'item'} onClick={()=>setItemType('csgo_type_knife')}>匕首</div>
                                <div className={itemType=='type_hands'?'item active':'item'} onClick={()=>setItemType('type_hands')}>手套</div>
                                <div className={itemType=='csgo_type_pistol'?'item active':'item'} onClick={()=>setItemType('csgo_type_pistol')}>手枪</div>
                                <div className={itemType=='csgo_type_sniperrifle'?'item active':'item'} onClick={()=>setItemType('csgo_type_sniperrifle')}>狙击步枪</div>
                                <div className={itemType=='csgo_type_rifle'?'item active':'item'} onClick={()=>setItemType('csgo_type_rifle')}>步枪</div>
                                <div className={itemType=='csgo_type_smg'?'item active':'item'} onClick={()=>setItemType('csgo_type_smg')}>微型冲锋枪</div>
                                <div className={itemType=='csgo_type_shotgun'?'item active':'item'} onClick={()=>setItemType('csgo_type_shotgun')}>散弹枪</div>
                                <div className={itemType=='csgo_type_machinegun'?'item active':'item'} onClick={()=>setItemType('csgo_type_machinegun')}>机枪</div>
                                <div className={itemType=='type_customplayer'?'item active':'item'} onClick={()=>setItemType('type_customplayer')}>探员</div>
                                <div className={itemType==''?'item active':'item'} onClick={()=>setItemType('')}>全部</div>
                            </div>
                        </div>
                    }
                    
                    <div className="right-wrap">
                        <div className="input-wrap">
                            <Input prefix={<img src={search} width="16px" />} placeholder="饰品名称搜索" value={itemName} onChange={(e)=>setItemName(e.target.value)} />
                            {window.innerWidth < 790 &&
                                <i className='filter' onClick={()=>showDrawer()}></i>
                            }
                            <Drawer
                                title="饰品类型"
                                placement={'right'}
                                closable={false}
                                onClose={onClose}
                                width={'78vw'}
                                open={open}
                                className="right-menu"
                                footer={
                                    <>  
                                        <div className='right-footer'>
                                            <Button className='btn' ghost onClick={()=>reset()}>重置</Button>
                                            <Button className='btn' type="primary" onClick={ ()=>setOpen(false) }>确认</Button>
                                        </div>
                                    </>
                                }
                            >   
                                <div className='left-btns'>
                                    <div className={itemType=='hot'?'item active':'item'} onClick={()=>setItemType('hot')}>热门</div>
                                    <div className={itemType==''?'item active':'item'} onClick={()=>setItemType('')}>全部</div>
                                    <div className={itemType=='csgo_type_knife'?'item active':'item'} onClick={()=>setItemType('csgo_type_knife')}>匕首</div>
                                    <div className={itemType=='type_hands'?'item active':'item'} onClick={()=>setItemType('type_hands')}>手套</div>
                                    <div className={itemType=='csgo_type_pistol'?'item active':'item'} onClick={()=>setItemType('csgo_type_pistol')}>手枪</div>
                                    <div className={itemType=='csgo_type_sniperrifle'?'item active':'item'} onClick={()=>setItemType('csgo_type_sniperrifle')}>狙击步枪</div>
                                    <div className={itemType=='csgo_type_rifle'?'item active':'item'} onClick={()=>setItemType('csgo_type_rifle')}>步枪</div>
                                    <div className={itemType=='csgo_type_smg'?'item active':'item'} onClick={()=>setItemType('csgo_type_smg')}>微型冲锋枪</div>
                                    <div className={itemType=='csgo_type_shotgun'?'item active':'item'} onClick={()=>setItemType('csgo_type_shotgun')}>散弹枪</div>
                                    <div className={itemType=='csgo_type_machinegun'?'item active':'item'} onClick={()=>setItemType('csgo_type_machinegun')}>机枪</div>
                                    <div className={itemType=='type_customplayer'?'item active':'item'} onClick={()=>setItemType('type_customplayer')}>探员</div>
                                </div>
                            </Drawer>

                        </div>
                        { window.innerWidth >790 &&
                            <div className="right-button">
                                <Button className="btn" onClick={()=>reset()}>重置</Button>
                                <Button className="btn" type="primary" onClick={()=>getList(1)}>搜索</Button>
                            </div>
                        }
                    </div>
                </div>
                {window.innerWidth < 790 &&
                    <div className="statistics" style={{marginTop:'10px'}}>总件数：{total}</div>
                }
                <div className="weapon-wrap">
                    {itemList.map((item)=>(
                        <div className="item" key={item.id}>
                            <div className="img-wrap">
                                <img src={item.itemImg} />
                            </div>
                            <div className="weapon-info">
                                <p className="name">{ item.itemName }</p>
                                <p className="price">预估价 <span>￥ {item.consultPrice} </span></p>
                                <div className="button" onClick={()=>{getTradeUrl()}}>我要回收</div>
                            </div>
                        </div>
                    ))}
                </div>
                {itemList.length<=0 &&
                    <Empty />
                }
                {total>pageSize &&
                    <div className="page-wrap">
                        <Pagination  pageSize={pageSize} total={total} onChange={ getList }/>
                    </div>
                }
            </div>
        </div>
    )
}

export default ReceiveList;