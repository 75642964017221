import Table from "@/components/Table"
import { Input, Space, Tag,Form,Select,DatePicker,Button,Switch  } from 'antd';
import { useEffect, useState } from "react";
import api from '@/api'

const { RangePicker } = DatePicker;
const columns = [
    {
      title: '下级用户ID',
      dataIndex: 'subordinateId',
      key: 'subordinateId',
    },
    {
      title: '成交订单金额',
      dataIndex: 'orderPrice',
      key: 'orderPrice',
    },
    {
      title: '奖金金额',
      dataIndex: 'awardPrice',
      key: 'awardPrice',
    },
    {
      title: '完成时间',
      key: 'successAt',
      dataIndex: 'successAt',
    }
  ];
  var title = ""
    // To disable submit button at the beginning.
  
    

const Invite = () =>{
    const [title,setTitle] = useState('')
    const [award,setAward] = useState(0)

    useEffect(()=>{
      if(window.innerWidth<790){
        setTitle(false)
        api.award.statistics.post({pageNum:1,pageSize:20}).then(res=>{
          if(res.code == 0){
            setAward(res.data.totalAward)
          }
        })
      }else{
        api.award.statistics.post({pageNum:1,pageSize:20}).then(res=>{
          if(res.code == 0){
            let stitle = `下级用户完成订单笔数：${ res.data.totalNum }  下级用户完成金额：¥${ res.data.totalPrice } 得到的奖励金额：¥ ${ res.data.totalAward }`
            setTitle(stitle)
          }
        })
      }
    },[])


    return(
        <div style={{padding:"0 15px"}}>
          {window.innerWidth<790 && <p>得到的奖励金额： <span style={{color:'#7F68FF'}}>¥{award}</span> </p>}
          <Table title={ title } columns={ columns } data={api.award.list} ></Table>
        </div>
    )
}
export default Invite