// 和用户相关的状态管理

import { createSlice } from "@reduxjs/toolkit"
import { setToken as _setToken,getToken,removeToken,setGuide} from '@/utils'
import api from '@/api'

const userStore = createSlice({
    name:"user",
    // 数据状态
    initialState:{
        token:getToken()?getToken():'',
        userInfo:{}
    },
    // 同步修改方法
    reducers:{
        setToken(state,action){
            state.token = action.payload
            // loaclstorage存一份
            _setToken(action.payload)
        },
        setUserInfo(state,action){
            state.userInfo = action.payload
        },
        clearUserInfo(state){
            state.token = '';
            state.userInfo = {}
            removeToken()
        }
    }
})

// 解构出actionCreater

const { setToken,clearUserInfo,setUserInfo } = userStore.actions

// 获取reducer函数

const userReducer = userStore.reducer

// 获取个人信息
const fetchUserInfo = ()=>{
    return async (dispatch) =>{
      const res = await api.user.info.get()
      if(res.code == 0){
        setGuide(res.data.isInit)
        dispatch(setUserInfo(res.data))
      }
    }
}


export { setToken ,clearUserInfo,setUserInfo,fetchUserInfo }

export default userReducer