import config from "@/config"
import http from "@/utils/request"

export default{
    banner:{
        url:`${config.API_URL}/system/getBannerList`,
        name:"轮播图",
        get: async function(){
            return await http.get(this.url)
        }
    },
    website:{
        url:`${config.API_URL}/system/getWebsiteInfo`,
        name:"网站信息",
        get: async function(){
            return await http.get(this.url)
        }
    },
    noticList:{
        url:`${config.API_URL}/system/getNoticeList`,
        name:"首页公告",
        get: async function(){
            return await http.get(this.url)
        }
    },
    orderList:{
        url:`${config.API_URL}/market/getSuccessOrderList`,
        name:"成交数据",
        get: async function(){
            return await http.get(this.url)
        }
    },
    content:{
        list:{
            url:`${config.API_URL}/system/getDocList`,
            name:"获取文章列表",
            post: async function(data,config={}){
                return await http.post(this.url,data,config)
            }
        },
        content:{
            url:`${config.API_URL}/system/getDocById/`,
            name:"获取文档内容",
            get: async function(id){
                return await http.get(this.url+id)
            }
        }
    }
}