import people from './img/people.png'
import order from './img/order.png'
import num from './img/num.png'
import './index.scss'
import { useSelector } from 'react-redux'

const Statistics = ()=>{
    const siteInfo = useSelector(state=>state.site.siteInfo)
    return(
        <div className="statistics-wrap">
            <div className="item">
                <div className="image-wrap">
                    <img src={people} alt="注册用户"></img>
                </div>
                <div className='text-wrap'>
                    
                        <p>累计注册用户{window.innerWidth > 790 && '人数'}  </p>
                    
                    <p className='num'>{ siteInfo.registerNum }人</p>
                </div>
            </div>
            <div className="item">
                <div className="image-wrap">
                    <img src={order} alt="订单数"></img>
                </div>
                <div className='text-wrap'>
                    <p>累计回收订单{window.innerWidth > 790 && '笔数'}</p>
                    <p className='num'>{ siteInfo.orderNum }笔</p>
                </div>
            </div>
            <div className="item">
                <div className="image-wrap">
                    <img src={num} alt="订单金额"></img>
                </div>
                <div className='text-wrap'>
                    <p>累计回收订单{window.innerWidth > 790 && '金额'}</p>
                    <p className='num'>{ siteInfo.price }元 </p>
                </div>
            </div>
        </div>
    )
}

export default Statistics