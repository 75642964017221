// 封装和token相关的方法 存 取 删


const TOKENKEY= 'is_guide'

function setGuide(flag){
    localStorage.setItem(TOKENKEY,flag)
}

function getGuide(){
    return  localStorage.getItem(TOKENKEY)
}

function removeGuide(){
    localStorage.removeItem(TOKENKEY)
}

export {
    setGuide,
    getGuide,
    removeGuide
}