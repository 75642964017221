import { Button, Form, Input, message, Select, Space } from 'antd';
import { useEffect, useState } from 'react';
import api from '@/api'
import { useDispatch } from 'react-redux';
import { fetchUserInfo } from '@/store/modules/user'

const Txform = ()=>{
    const [channel,setChannel] = useState('001')
    const [config,setConfig] = useState({})
    const dispatch = useDispatch()
    const [flag,setFlag] = useState(false)

    const onFinish = (values) => {
        console.log(values)
        if(/[a-zA-Z]/.test(values.amount)){
            message.error("请输入正确的提现金额");
            return;
        }
        if(values.amount<config.singleOrderMinWithdrawalAmount){
            message.error("提现金额不得低于"+config.singleOrderMinWithdrawalAmount);
            return;
        }
        if(values.amount>config.singleOrderMaxWithdrawalAmount){
            message.error("提现金额不得高于"+config.singleOrderMaxWithdrawalAmount);
            return;
        }

        if(!values.account){
            message.error("请输入账号");
            return;
        }

        if(!values.name){
            message.error("请输入姓名");
            return;
        }
        setFlag(true)
        values.channel = '001'
        api.withdraw.apply.post(values).then(res=>{
            if(res.code == 0){
                message.success("申请提现成功,请前往提现记录查看进度。")
                dispatch(fetchUserInfo());
            }else{
                message.error(res.msg)
            }
            setFlag(false)
        })
    };

    useEffect(()=>{
        api.withdraw.info.get().then(res=>{
            if(res.code == 0){
                setConfig(res.data)
            }
        })
    },[])

    return(
        <>
            <Form
                name="basic"
                labelCol={{
                    xs: 6,
                    xl:4
                }}
                wrapperCol={{
                    xs:18,
                    xl:10
                }}
                style={{
                    maxWidth: 600,
                }}
                onFinish={onFinish}>
                <Form.Item label="提现金额" name="amount" >
                    <div>
                        <Input placeholder={config.singleOrderMinWithdrawalAmount+'-'+config.singleOrderMaxWithdrawalAmount} />
                        {window.innerWidth>790 && <span className='form-tips' style={{color:'#E98E1B'}}>注：提现最低{config.singleOrderMinWithdrawalAmount}起，手续费{ config.withdrawalFeeRate*100 } %</span>}
                    </div>
                </Form.Item>
                <Form.Item label="提现账号" name="account">
                    <Input placeholder='请输入提现账号'/>
                </Form.Item>

                <Form.Item label="账号姓名" name="name">
                    <Input placeholder='请输入真实姓名'/>
                </Form.Item>

                <Form.Item label="提现渠道" name="channel">
                    <div className='txBtns'>
                        <div className='item active'>
                            <i className='zfb'></i>
                            支付宝
                        </div>
                    </div>
                </Form.Item>

                <Form.Item
                    wrapperCol={{
                        offset: window.innerWidth<790?0:4,
                        span: 8,
                    }}>
                    <Button type="primary" htmlType="submit" style={{width:'100%'}} loading={flag}>
                        确认提现
                    </Button>
                </Form.Item>
            </Form>
            <div className='descript'>
                <p>提现说明</p>
                <p>1.单笔限额{config.singleOrderMinWithdrawalAmount+'-'+config.singleOrderMaxWithdrawalAmount}元</p>
                <p>2. 提现手续费{ config.withdrawalFeeRate*100 }%手续费</p>
            </div>
        </>
       
        
    )
}

export default Txform;