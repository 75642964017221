import './index.scss'
import api from '@/api'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'

const Notic = ()=>{
    const close =()=>{
        let notic =  document.getElementById('notic')
        notic.style.display = 'none';
    }

    const [content,setContent] = useState({})
    const navigate = useNavigate();

    useEffect(()=>{
        let data={
            pageNum:1,
            pageSize:1,
            docCateTag:'xtgg'
        }
        api.board.content.list.post(data).then(res=>{
            if(res.code == 0){
                setContent(res.data[0])
            }
        })
        
    },[])

    return(
        <div className='notic-wrap' id="notic">
            <div className='left' onClick={()=>navigate('/article/'+ content.id) }>
                <i className='warning'></i>
                { content.docDescribe }
            </div>
            <i className='close' onClick={close}></i>
        </div>
    )
}

export default Notic