import config from "@/config"
import http from "@/utils/request"

export default{
    apply:{
        url:`${config.API_URL}/user/applyWithdraw`,
        name:"申请提现",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    list:{
        url:`${config.API_URL}/user/getUserWithdrawList`,
        name:"提现记录",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    info:{
        url:`${config.API_URL}/user/getWithdrawConfig`,
        name:"获取提现相关配置",
        get: async function(){
            return await http.get(this.url)
        }
    }
}