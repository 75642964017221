import '../index.scss'
import { Pagination, message } from 'antd';
import more from '../img/more.png'
import Dialog from "@/components/Dialog";
import { useState,useRef } from "react";

import api from '@/api'
import { tool } from '@/utils'

const Wpage = (props)=>{
    const { list,total } = props
    const [showDetail,setShowDetail] = useState(false)
    const [weapon,setWeapon] = useState([])
    const [weponTotal,setWeaponTotal] = useState(0)
    const storeIdRef = useRef(0)


    const operFunc = (flag)=>{
        setShowDetail(flag)
    }

    const showMore = (id)=>{
        storeIdRef.current = id
        getData(1)
        setShowDetail(true)
    }

    const apply=(id)=>{
        api.user.apply.get(id).then(res=>{
            if(res.code == 0){
                tool.copy(res.data.tradeUrl)
                message.success('交易链接已复制，请前往个人中心查看链接有效期。')
            }else{
                message.error(res.msg)
            }
        })
    }

    const getData=(page)=>{
        let params = {
            pageNum:page,
            pageSize:9,
            storeId:storeIdRef.current
        }
        api.shop.detail.post(params).then(res=>{
            if(res.code == 0){
                setWeapon(res.data)
                setWeaponTotal(res.totalCount)
            }
        })
    }


    return(
        <div className='shop-wrap'>
            <div className='shoplist'>
                {
                    list.map((item)=>(
                        <div className='shop' key={item}>
                            <div className='shop-info'>
                                <div>
                                    <div className='image-wrap'>
                                        <img src={item.icon}></img>
                                    </div>
                                    <div>
                                        <h3>{item.name}</h3>
                                        <p className='tip'>店铺描述:{item.description}</p>
                                        <p className='discount'>回收折扣：{ item.discount*100 }%</p>
                                        <p>累计回收笔数：{ item.orderCount }</p>
                                    </div>
                                </div>
                                <div className='bottom'>
                                    <span>开店时间：{ item.createAt }</span>
                                    <span className='btn' onClick={()=>apply(item.id)}><i className='link'></i> 申请专属交易链接 </span>
                                </div>
                            </div>
                            <div className='goods'>
                                {
                                    item.itemList.map((good)=>(
                                        <div className='item' key={good}>
                                            <div className='image-wrap'>
                                                <img src={good.itemImg}></img>
                                            </div>
                                            <div className='weapon-info'>
                                                <p>{good.itemName}</p>
                                                <p className='price'>回收价<span>￥{good.price}</span></p>
                                            </div>
                                        </div>
                                    ))
                                }
                                
                                <div className='more' onClick={()=>showMore(item.id)}> <img src={more} width="24" height={24} ></img> </div>
                            </div>
                        </div>
                    ))
                }
                
            </div>
            <div className='page-wrap'>
                <Pagination defaultCurrent={1} total={total} />
            </div>

            <Dialog title="回收饰品" 
                footer = {<></>}
                vis={ showDetail }
                width={'954px'}
                operDialogFunc={operFunc}>
                <div className="goods-list">
                    {weapon.map((item)=>(
                        <div className='item' key={item}>
                            <div className='image-wrap'>
                                <img src={item.itemImg}></img>
                            </div>
                            <div className='weapon-info'>
                                <p>{ item.itemName }</p>
                                <p className='price'>回收价<span>￥{ item.price }</span></p>
                            </div>
                        </div>
                    ))}
                </div>
                <div className='page-wrap' style={{textAlign:'center',marginTop:'24px'}}>
                    <Pagination defaultCurrent={1} total={ weponTotal } pageSize={9} onChange={getData}/>
                </div>
            </Dialog> 
       </div>
    )
}

export default Wpage;