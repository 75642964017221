import title from './img/title.png'
import inner from './img/inner.png'
import step1 from './img/step1.png'
import step2 from './img/step2.png'
import step3 from './img/step3.png'
import step4 from './img/step4.png'
import step5 from './img/step5.png'
import step from './img/step.png'
import stepM from './img/step_m.png'

import './index.scss'
import { useSelector } from 'react-redux'

const Steps = ()=>{
    const siteInfo = useSelector(state=>state.site.siteInfo)
    return(
        <div className="step-wrap">
            <h2 className="title">
                <img src={title} width="332" alt="subtitle"></img>
            </h2>
            { window.innerWidth > 790 &&
                <img src={step} alt="注册账号" width='1200px'></img>
            }
            { window.innerWidth <= 790 &&
                <img src={stepM} alt="注册账号" width='100%'></img>
            }
        </div>
    )
}

export default Steps