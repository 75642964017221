import Notic from "@/pages/Board/components/Notic";
import UserInfo from "./components/UserInfo";
import { Space } from "antd";
import Withdrawal from "./components/Withdrawal";

const Center = ()=>{
    return(
        <Space direction="vertical" size="middle" style={{ display: 'flex' }}>
            <Notic />
            {window.innerWidth>790 && <UserInfo /> } 
            <Withdrawal />
        </Space>
    )
}
export default Center;