import { Carousel } from 'antd';
import './index.scss'
import api from '@/api'
import { useEffect, useState } from 'react';



const Banner = ()=>{

    const [banners,setBanners] = useState([])

    useEffect(()=>{
        api.board.banner.get().then(res=>{
            if(res.code == 0){
                setBanners(res.data)
            }
        })
    },[])

    return(
        <div className='banner-wrap'>
            <Carousel autoplay>
                {banners.map((item)=>(
                    <div key={item.id} onClick={ ()=>{ window.location.href=item.url } }>
                        <h3 style={ {
                            margin: 0,
                            height: window.innerWidth<=790?'115px':'400px',
                            color: '#fff',
                            lineHeight: window.innerWidth<=790?'115px':'400px',
                            textAlign: 'center',
                            background: `url(${item.path}) no-repeat`,
                            backgroundSize:'100% 100%'
                            } }></h3>
                    </div>
                ))}
            </Carousel>
        </div>
    )
}

export default Banner