import { Table as AnTable } from 'antd';
import { useEffect, useState } from 'react';
import './index.scss'

const Table = (props)=>{
    const { title,children,columns,data,filterForm,expandable} = props
    const [totalCount,setTotalCount] = useState(0)
    const [list,setList] = useState([])

    const pageSize = 5;
    
    async function getList(page){
        let params ={
            pageNum:page,
            pageSize:pageSize,
            ...filterForm
        }
        let res = await data.post(params)
        setList(res.data)
        setTotalCount(res.totalCount)
    }

    useEffect(()=>{
        getList(1)
    },[filterForm])

    const onchange = (val) => {
        getList(val)
    }


    return(
        <>  
            {
                title && 
                 <div className='topTitle'>
                    {title}
                </div>
            }
            {children}
            <AnTable className='myTable' expandable={{...expandable}} columns={columns} dataSource={list} pagination={{position:['bottomCenter'],pageSize:pageSize,total:totalCount,onChange:onchange}}/>
        </>
    )
}

export default Table;