import { Button, Checkbox, Form, Input,message,Space } from 'antd';
import { useState } from 'react';
import { useDispatch } from 'react-redux'
import VerifySlideFixed from "../../Verify/verifySlideFixed";
import VerifyPointFixed from "../../Verify/verifyPointFixed";
import { setToken as _setToken } from '@/utils/token';
import { setToken ,fetchUserInfo } from '@/store/modules/user';
import { useNavigate } from 'react-router-dom'

import api from  "@/api"

const LoginBySms = (props)=>{
    const { changeType,close } = props
    const [ form ] = Form.useForm();
    const navigate = useNavigate();
    const dispatch = useDispatch()

    const [slideShow,setSlideShow] = useState(false)
    const [time,setTime] = useState(60)
    const [flag,setFlag] = useState(false)

    const onFinish = (values) => {
        if(!values.remember){
            message.error("请先阅读并同意《服务协议》、《隐私政策》");
            return;
        }
        if(!values.phone || values.phone.length!= 11){
            message.error("请输入正确的手机号码");
            return;
        }
        if( /[a-zA-Z]/.test(values.phone)){
            message.error("请输入正确的手机号");
            return;
        }
        
        if(!values.smsCode){
            message.error("请输入验证码");
            return;
        }
        

        api.user.login.BySms.post(values).then(res=>{
            if(res.code == 0){
                _setToken(res.data.token)
                dispatch(setToken(res.data.token))
                dispatch(fetchUserInfo())
                navigate('/center')
                // 2.提示一下用户
                message.success('登录成功')
                close()
            }else{
                message.error(res.msg)
            }
        })
    };
    const onFinishFailed = (errorInfo) => {
    };
    
    const verifyPointFixedChild = (data)=>{
        setSlideShow(data)
    }

    const sendSms = ()=>{
        if(flag) return;
        var value = form.getFieldValue()
        if(!value.phone){
            message.error('请输入正确的手机号')
            return;
        }
        if(value.phone.length !=11){
            message.error('请输入正确的手机号')
            return;
        }
        if( /[a-zA-Z]/.test(value.phone)){
            message.error("请输入正确的手机号");
            return;
        }
        setSlideShow(true)
    }
    const onsuccess =(data)=>{
        setFlag(true)
        setSlideShow(false)
        var data= {
            phone:form.getFieldValue().phone,
            codeType:'login',
            captchaVerification:data.captchaVerification
        }
        api.common.sendSms.post(data).then(res=>{
            if(res.code == 0){
                message.success('短信已发送，请注意查收！');
                var intime = 60;
                const inter = setInterval(()=>{
                    if(intime<=0){
                        setTime(60)
                        clearInterval(inter)
                        setFlag(false)
                    }else{
                        intime--;
                        setTime(intime)
                    }
                },1000)
            }else{
                message.error(res.msg)
                setFlag(false)
            }
        })
    }
    return(
        <Form
            name="smsform"
            className='form'
            form = {form}
            labelCol={{
                xl:6,
                xs:6
            }}
            wrapperCol={{
                xl: 18,
                xs:18
            }}
            
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
                label="手机号"
                name="phone">
                <Input placeholder="请输入手机号" />
            </Form.Item>

            <Form.Item
                label="验证码"
                name="smsCode">
                <Space.Compact >
                    <Input placeholder="请输入验证码"/>
                    <Button type="link" onClick={()=>sendSms()} disabled={flag}> {flag && (<> {time}s </>)}  获取验证码  </Button>
                    { window.innerWidth>790 &&  
                        <VerifySlideFixed isSlideShow={slideShow} verifyPointFixedChild={verifyPointFixedChild}  verifyPointFixedSuccess ={ onsuccess }/> 
                    }
                    { window.innerWidth<=790 &&  
                        <VerifyPointFixed isPointShow={slideShow} verifyPointFixedChild={verifyPointFixedChild}  verifyPointFixedSuccess ={ onsuccess }/> 
                    }
                </Space.Compact>
            </Form.Item>
            <div className='btns'>
                <div onClick={()=>changeType('PWD')}>密码登录</div>
                <div onClick={()=>changeType('Forget')}>忘记密码</div>
            </div>
            <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox >我已经阅读且同意 <a href="/protocol/fwxy" target='_blank' className='link'> 《服务协议》</a>、<a href="/protocol/fwxy" target='_blank' className='link'>《隐私协议》</a></Checkbox>
            </Form.Item>
            <div className="footer">
                <Button type='primary' htmlType='submit' className='button'>立即登录</Button>
            </div>
        </Form>
    )
}

export default LoginBySms;