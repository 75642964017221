import Table from "@/components/Table"
import { Input, Space, Tag,Form,Select,DatePicker,Button,Switch,Empty,Pagination  } from 'antd';
import { useEffect, useState } from "react";
import api from '@/api'
import { tool } from "@/utils";

const columns = [
    {
      title: '流水号',
      dataIndex: 'txOrderNo',
      key: 'txOrderNo',
    },
    {
      title: '提现金额',
      dataIndex: 'txAmount',
      key: 'txAmount',
    },
    {
      title: '到账金额',
      key: 'txRealAmount',
      dataIndex: 'txRealAmount',
    },
    {
      title: '状态',
      key: 'txStatus',
      dataIndex: 'txStatus',
      render:(_,{ txStatus })=>(
        <>
          { txStatus == 0 && <span style={{color:'#E98E1B'}}>审核中</span> }
          { txStatus == 1 && <span style={{color:'#20BB19'}}>已到账</span> }
          { txStatus == 2 && <span style={{color:'#DE1F0F'}}>已驳回</span> }
        </>
      )
    },
    {
      title: '提现时间',
      key: 'txCreateDate',
      dataIndex: 'txCreateDate',
      render: (_, { txCreateDate }) => (
        tool.dateFormat(txCreateDate)
      )
    },
    {
      title: '到账时间',
      key: 'txSuccessDate',
      dataIndex: 'txSuccessDate',
      render: (_, { txSuccessDate }) => (
        txSuccessDate?(tool.dateFormat(txSuccessDate)):'--'
      )
    },{
      title: '订单备注',
      key: 'txRejectReason',
      dataIndex: 'txRejectReason'
    }
  ];

const Txrecord = () =>{
    const [total,setTotal] = useState(0)
    const [list,setList] = useState([]);

    useEffect(()=>{
      getData(1)
    },[])

    async function getData(page){
      let params = {
        pageNum:page,
        pageSize:5
      }
      let res = await api.withdraw.list.post(params);
      if(res.code == 0){
        setTotal(res.totalCount)
        setList(res.data)
      }
    }


    return(
      <>
        { window.innerWidth>790 &&  <Table title={false} columns={columns} data={api.withdraw.list} ></Table>}
        
        { window.innerWidth<790 &&
          <div className="record-list">
            {list.map((item)=>(
              <div className="item" key={item.txOrderNo}>
                <div className="top">
                  <div>
                    <i className="time"></i>
                    <span> { tool.dateFormat(item.txCreateDate)} </span>
                  </div>
                  { item.txStatus == 0 && <span style={{color:'#E98E1B'}}>审核中</span> }
                  { item.txStatus == 1 && <span style={{color:'#20BB19'}}>已到账</span> }
                  { item.txStatus == 2 && <span style={{color:'#DE1F0F'}}>已驳回</span> }
                </div>
                <div>
                  <p>变动金额： <span>￥ { item.txAmount }</span></p>
                  <p>到账金额： <span>￥{ item.txRealAmount }</span></p>
                </div>
                <div>
                  <p>{ item.txOrderNo }</p>
                </div>
              </div>
            ))}
            {list.length<=0 && <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />}
            {total>5 && <Pagination defaultCurrent={1} pageSize={5} total={total} onChange={ getData }/> } 
          </div>
        }
      </>
    )
}
export default Txrecord