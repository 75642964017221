import { useSearchParams,useParams } from "react-router-dom"
import api from '@/api'
import { useEffect, useState } from "react"

const Article = ()=>{
    // const [ params ] = useSearchParams()
    // let  id = params.get('id')
    // let name = params.get('name')
    const [content,setContent] = useState({})

    const params =  useParams()
    const id = params.id
    
    useEffect(()=>{
        api.board.content.content.get(id).then(res=>{
            if(res.code == 0){
                setContent(res.data)
            }
        })
    },[])

    return <div style={{padding:"12px"}} dangerouslySetInnerHTML={{ __html: content.docContent }}></div>
}

export default Article