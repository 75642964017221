import Notic from "./components/Notic"
import Banner from './components/Banner'
import Statistics from "./components/Statistics"
import Order from './components/Order'
import Steps from './components/Steps'

const Board = ()=>{
    return (
        <>
           <Notic />
           <Banner />
           <Steps/>
           <Order />
           <Statistics />
        </>
    )
}

export default Board