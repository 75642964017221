import {  Checkbox, Form, Input,Space,Button,message } from 'antd';
import VerifySlideFixed from "../../Verify/verifySlideFixed";
import VerifyPointFixed from "../../Verify/verifyPointFixed";
import { useSearchParams } from "react-router-dom"

import { useState } from 'react';
import api from  "@/api"


const Register = (props)=>{
    const [ params ] = useSearchParams()
    const  promote = params.get('promote')
    console.log(promote)
    const [slideShow,setSlideShow] = useState(false)
    const [ form ] = Form.useForm();
    const [time,setTime] = useState(60)
    const [flag,setFlag] = useState(false)
    const { changeType } = props

    const onFinish = (values) => {
        if(!values.remember){
            message.error("请先阅读并同意《服务协议》、《隐私政策》");
            return;
        }
        if(!values.phone || values.phone.length!= 11){
            message.error("请输入正确的手机号码");
            return;
        }

        if( /[a-zA-Z]/.test(values.phone)){
            message.error("请输入正确的手机号");
            return;
        }
        if(!values.password){
            message.error("请输入密码");
            return;
        }
        if(values.password !== values.ckpassword){
            message.error("请确认密码");
            return;
        }
        if(!values.smsCode){
            message.error("请输入验证码");
            return;
        }
        


        api.user.register.post(values).then(res=>{
            if(res.code == 0){
                message.success("注册成功，请返回登录")
                changeType('SMS')
            }else{
                message.error(res.msg)
            }
        })
    };
    const onFinishFailed = (errorInfo) => {
    };

    const verifyPointFixedChild = (data)=>{
        setSlideShow(data)
    }
    const sendSms = ()=>{
        if(flag) return;
        var value = form.getFieldValue()
        if(!value.phone){
            message.error('请输入正确的手机号')
            return;
        }
        if(value.phone.length !=11){
            message.error('请输入正确的手机号')
            return;
        }
        if( /[a-zA-Z]/.test(value.phone)){
            message.error("请输入正确的手机号");
            return;
        }
        setSlideShow(true)
    }
    const onsuccess =(data)=>{
        setFlag(true)
        setSlideShow(false)
        var data= {
            phone:form.getFieldValue().phone,
            codeType:'register',
            captchaVerification:data.captchaVerification
        }
        api.common.sendSms.post(data).then(res=>{
            if(res.code == 0){
                message.success('短信已发送，请注意查收！')
                var intime = 60;
                const inter = setInterval(()=>{
                    if(intime<=0){
                        setTime(60)
                        clearInterval(inter)
                        setFlag(false)
                    }else{
                        intime--;
                        setTime(intime)
                    }
                },1000)
            }else{
                message.error(res.msg)
                setFlag(false)
            }
        })
    }
    return(
        <Form
            name="registerform"
            form = {form}
            className='form'
            labelCol={{
                xl:6,
                xs:6
            }}
            wrapperCol={{
                xl: 18,
                xs:18
            }}
            
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item
                label="手机号"
                name="phone">
                <Input placeholder="请输入手机号"/>
            </Form.Item>

            <Form.Item
                label="密码"
                name="password">
                <Input.Password placeholder="请输入密码"/>
            </Form.Item>

            <Form.Item
                label="确认密码"
                name="ckpassword">
                <Input.Password placeholder="请输入密码"/>
            </Form.Item>

            <Form.Item
                label="验证码"
                name="smsCode">
                <Space.Compact >
                    <Input placeholder="请输入验证码"/>
                    <Button type="link" onClick={sendSms} disabled={flag}> {flag && (<> {time}s </>)}  获取验证码</Button>
                    { window.innerWidth>790 &&  
                        <VerifySlideFixed isSlideShow={slideShow} verifyPointFixedChild={verifyPointFixedChild}  verifyPointFixedSuccess ={ onsuccess }/> 
                    }
                    { window.innerWidth<=790 &&  
                        <VerifyPointFixed isPointShow={slideShow} verifyPointFixedChild={verifyPointFixedChild}  verifyPointFixedSuccess ={ onsuccess }/> 
                    }
                </Space.Compact>
            </Form.Item>

            <Form.Item
                label="邀请码"
                name="promoteCode">
                <Input placeholder="请输入邀请码（选填）" defaultValue={promote}/>
            </Form.Item>
            <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox >我已经阅读且同意 <a href="/protocol/fwxy" target='_blank' className='link'> 《服务协议》</a>、<a href="/protocol/fwxy" target='_blank' className='link'>《隐私协议》</a></Checkbox>
            </Form.Item>
            <div className="footer">
                <Button type='primary' htmlType='submit' className='button'>立即注册</Button>
            </div>
        </Form>
    )
}

export default Register;