// 封装高阶组件
// 核心逻辑： 有token 正常跳转， 无token 去登录

import { getToken } from "@/utils";
import { useState } from "react";
import { Navigate } from 'react-router-dom'
import Layout from "../pages/Layout";
import Login from "./Login";
import NoLogin from "./NoLogin";

function AuthRoute({children}){
    const token = getToken()

    const [visiable,setVisiable]=useState(true)

    const operDialogFunc = (flag)=>{
        setVisiable(flag)
    }

    var path = window.location.pathname

    if(token || path=='/'){
        return <>{children}</>
    }else{
        return (
            <>
                <Layout>
                    <NoLogin />
                    <Login vis={visiable} operDialogFunc={ operDialogFunc }/>
                </Layout>
            </>
        )
    }

}
export { AuthRoute } 