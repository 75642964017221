import config from "@/config"
import http from "@/utils/request"

export default{
    type:{
        list:{
            url:`${config.API_URL}/market/getItemType`,
            name:"获取饰品类型列表",
            get: async function(){
                return await http.get(this.url)
            }
        }
    },
    list:{
        url:`${config.API_URL}/market/getRecommendItemList`,
        name:"获取推荐回收饰品列表",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    }

}