import { Button, Drawer, Avatar, message } from 'antd';
import { useState,useEffect } from 'react';
import { useNavigate } from 'react-router-dom'
import Login from "@/components/Login";
import { UserOutlined } from '@ant-design/icons';
import "./index.scss"
import { getToken } from '@/utils'
import { useDispatch, useSelector } from 'react-redux'
import { fetchUserInfo,clearUserInfo } from '@/store/modules/user'

const MTopBar = ()=>{
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();
    const token = getToken();
    const pathname = window.location.pathname
    const dispatch = useDispatch()
    const showDrawer = () => {
      setOpen(true);
    };
    const onClose = () => {
      setOpen(false);
    };
    const [visiable,setVisiable]=useState(false)
    const openDialog = ()=>{
        setVisiable(true)
    }
    const operDialogFunc = (flag)=>{
        setVisiable(flag)
    }

    const logout = ()=>{
        dispatch( clearUserInfo() )
        navigate('/')
        // 2.提示一下用户
        message.success('退出成功')
    }
    useEffect(()=>{
        if(token){
            dispatch(fetchUserInfo())
        }
    },[dispatch])
    const userInfo =  useSelector(state=>state.user.userInfo)

    const footer = ()=>{
        if(!token){
            return <Button type='default' style={{width:'100%'}} onClick={openDialog}>立 即 登 录</Button>
        }else{
            return <Button type='default' style={{width:'100%'}} onClick={logout}>退 出 登 录</Button>
        }
    }

    return(
        <div className='mtopBar'>
            <div className="left">
                <i className='menu' onClick={showDrawer}></i>
                <div className="logo-wrap">
                    <img src="/logo192.png" alt="logo"></img>
                </div>
                <Drawer
                    title={
                        <div className="logo-wrap">
                            <img src="/logo192.png" alt="logo" height="44px"></img>
                        </div>
                    }
                    className="left-menu"
                    placement={'left'}
                    closable={false}
                    onClose={onClose}
                    width={'50vw'}
                    open={open}
                    footer={footer()}
                >   
                    <div className="menus">
                        <div className={pathname=='/'?'item active':'item'} onClick={() => navigate('/')}>
                            <i className="index"></i>
                            <span>首页</span>
                        </div>
                        <div className={pathname=='/receivelist'?'item active':'item'} onClick={() => navigate('/receivelist')}>
                            <i className="receivelist"></i>
                            <span>回收列表</span>
                        </div>
                        <div className={pathname=='/shops'?'item active':'item'} onClick={() => navigate('/shops')}>
                            <i className="shop"></i>
                            <span>回收店铺</span>
                        </div>
                        <div className={pathname=='/order'?'item active':'item'} onClick={() => navigate('/order')}>
                            <i className="order"></i>
                            <span>我的订单</span>
                        </div>
                        <div className={pathname=='/invite'?'item active':'item'} onClick={() => navigate('/invite')}>
                            <i className="invite"></i>
                            <span>邀请奖金</span>
                        </div>
                        <div className={pathname=='/notic'?'item active':'item'} onClick={() => navigate('/notic')}>
                            <i className="notic"></i>
                            <span>系统公告</span>
                        </div>
                        <div className={pathname=='/center'?'item active':'item'} onClick={() => navigate('/center')}>
                            <i className="center"></i>
                            <span>个人中心</span>
                        </div>
                    </div>
                    
                </Drawer>
            </div>
            <div className='right'>
                
                {!token && <Button type='primary' onClick={openDialog}>登录</Button>}
                {token && 
                    <div className="userinfo" onClick={ ()=>{navigate('/center')}}> 
                        <div className="left"> <p>{ userInfo.nickname }</p> <p>￥ { userInfo.availableBalance } </p></div>  
                        <div>
                            <Avatar size={32}> { userInfo.nickname && userInfo.nickname.substring(0,1) } </Avatar>
                        </div> 
                    </div>
                }
            </div>
            <Login vis={visiable} operDialogFunc={ operDialogFunc }/>
        </div>
    )
}

export default MTopBar;