import {  Button, Checkbox, Form, Input, message } from 'antd';
import { useDispatch } from 'react-redux'
import { useNavigate } from 'react-router-dom'
import { setToken as _setToken } from '@/utils/token';
import { fetchUserInfo, setToken } from '@/store/modules/user';
import api from '@/api'

const LoginByPwd = (props)=>{
    const navigate = useNavigate();
    const dispatch = useDispatch()
    var flag = true;
    const { changeType,close } = props
    const onFinish =async (values) => {
        if(!values.remember){
            message.error("请先阅读并同意《服务协议》、《隐私政策》");
            return;
        }
        if(!values.phone || values.phone.length!= 11){
            message.error("请输入正确的手机号码");
            return;
        }

        if( /[a-zA-Z]/.test(values.phone)){
            message.error("请输入正确的手机号");
            return;
        }
        if(!values.password){
            message.error("请输入密码");
            return;
        }
        if(flag){
            flag = false;
            api.user.login.ByPwd.post(values).then(res=>{
                if(res.code == 0){
                    _setToken(res.data.token)
                    dispatch(setToken(res.data.token))
                    dispatch(fetchUserInfo())
                    navigate('/center')
                    // 2.提示一下用户
                    message.success('登录成功')
                    close()
                    flag = true
                }else{
                    message.error(res.msg)
                    flag = true
                }
            })
        }
        
        
    };
    const onFinishFailed = (errorInfo) => {
    };
    return(
        <Form
            name="pwdform"
            className='form'
            labelCol={{
                xl: 6,
                xs:6
            }}
            wrapperCol={{
                xl: 18,
                xs:18
            }}
            
            initialValues={{
                remember: true,
            }}
            onFinish={onFinish}
            onFinishFailed={onFinishFailed}
            autoComplete="off">
            <Form.Item label="手机号" name="phone">
                <Input placeholder="请输入手机号"/>
            </Form.Item>

            <Form.Item
                label="密码"
                name="password">
                <Input.Password placeholder="请输入密码"/>
            </Form.Item>
            <div className='btns'>
                <div onClick={()=>changeType('SMS')}>验证码登录</div>
                <div onClick={()=>changeType('Forget')}>忘记密码</div>
            </div>
            <Form.Item name="remember" valuePropName="checked" noStyle>
                <Checkbox >我已经阅读且同意 <a href="/protocol/fwxy" target='_blank' className='link'> 《服务协议》</a>、<a href="/protocol/fwxy" target='_blank' className='link'>《隐私协议》</a></Checkbox>
            </Form.Item>
            <div className="footer">
                <Button type='primary' loading={!flag} htmlType='submit' className='button'>立即登录</Button>
            </div>
        </Form> 
    )
}

export default LoginByPwd;