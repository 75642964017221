import config from "@/config"
import http from "@/utils/request"

export default{
    list:{
        url:`${config.API_URL}/user/getStoreList`,
        name:"获取下级用户成交列表",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    },
    detail:{
        url:`${config.API_URL}/user/getStoreItemList`,
        name:"获取店铺回收饰品详情",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    }
}