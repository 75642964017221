import './index.scss'
import title from './img/title.png'
import api from '@/api'
import { useEffect, useState } from 'react'
import { Carousel } from 'antd'


const Order = ()=>{
    // const orderList = [
    //     {imgUrl:'https://file.v5item.com/items/1eaac6dadb0f8cae0d73391de29c9c28.jpg',name:'AK-47 | 墨岩 (久经沙场)',price:'16.1'},
    //     {imgUrl:'https://file.v5item.com/items/c4cd8d431770c8af87a9e810e9896325.jpg',name:'裂空武器箱',price:'1.68'},
    //     {imgUrl:'https://file.v5item.com/items/65d57e1dc04080e29d32c8b3ac2eef0c.jpg',name:'2023年巴黎锦标赛挑战组印花胶囊',price:'1.3'},
    //     {imgUrl:'https://file.v5item.com/items/4bb0118906dbd2dd821a3208c53d9ffd.jpg',name:'印花 | FURIA | 2020 RMR',price:'0.06'},
    //     {imgUrl:'https://file.v5item.com/items/6ec0b179d8bfba54efba4fdab2c53b17.jpg',name:'海军上尉里克索尔 | 海军水面战中心海豹部队',price:'54.48'},
    // ]

    const [orderList,setOrderList] = useState([])

    useEffect(()=>{
        api.board.orderList.get().then(res=>{
            if(res.code == 0){
                let list = [];
                let index = 0;
                list[index] = []
                if(window.innerWidth<790){
                    res.data.forEach(element => {
                        if(list[index].length>=6){
                            list[++index]=[]
                        }
                        list[index].push(element)
                    });
                }else{
                    res.data.forEach(element => {
                        if(list[index].length>=5){
                            list[++index]=[]
                        }
                        list[index].push(element)
                    });
                }
               
                setOrderList(list)
            }
        })
    },[])

    const format = (date)=>{
        let nowDate = Date.parse(new Date());
        let cutDate = nowDate/1000 - date;

        let day = parseInt(cutDate/60/60/24);
        let hour = parseInt(cutDate/60/60);
        let min = parseInt(cutDate/60);
        let sec = parseInt(cutDate);

        if(day>0){
            return day+'天前';
        }else if(hour > 0){
            return hour+'小时前';
        }else if(min > 0){
            return min+'分钟前';
        }else{
            return sec+'秒前'
        }
    }

    return(
        <div className="order-wrap">
            <h2 className="title">
                <img src={title} width="332" alt="subtitle"></img>
            </h2>
            
            <Carousel autoplay dots={false} speed={1000} autoplaySpeed={2000}>
                {orderList.map((item,index) =>(
                    <div className="order-list-wrap" key={index}>
                        {item.map((order)=>(
                            <div className="item" key={order.successTime}>
                                <div className='time'> {format(order.successTime)} </div>
                                <div className="top-wrap">
                                    <div className="img-wrap">
                                        <img src={order.imageURL} alt={order.itemName}></img>
                                    </div>
                                </div>
                                <div className="weapon-info">
                                    <p className="name">{ order.itemName }</p>
                                    <p className="price"> <span className='label'>回收价</span>  ￥{ order.price }</p>
                                </div>
                            </div>
                        ))}
                    </div>
                ))}
            </Carousel>
        </div>
    )
}
export default Order