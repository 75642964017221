// axios 的封装处理

import axios from "axios"

import { getToken } from "@/utils"
import { notification } from "antd"
import { removeToken } from "./token"
import { clearUserInfo } from "../store/modules/user"
import router from "../router"
import sysConfig from "@/config"


axios.defaults.baseURL = ''
axios.defaults.timeout = sysConfig.TIMEOUT


//添加请求拦截器
// 在请求发送之前做拦截插入一些配置
axios.interceptors.request.use((config) =>{
    // 操作这个config 主任token数据
    const token = getToken()
    if(token){
        config.headers[sysConfig.TOKEN_NAME] = sysConfig.TOKEN_PREFIX + token
    }
    if(!sysConfig.REQUEST_CACHE && config.method == 'get'){
        config.params = config.params || {};
        config.params['_'] = new Date().getTime();
    }
    Object.assign(config.headers,sysConfig.HEADERS)
    return config
},(error)=>{
    return Promise.reject(error)
})


// 添加响应拦截器
axios.interceptors.response.use((response)=>{
    // 2xx 范围内的状态码都回出发该函数
    // 对相应数据做点什么
	if(response.data.code == 500){
		notification.error({
			message:"请求错误",
			description:"您的账号已在别的地方登录，请重新登录！"
		});
		removeToken()
		clearUserInfo()
		router.navigate('/')
		return response;
	}

    return response
},(error)=>{
    // 超出2xx范围的状态码都会触发该函数
    //对相应错误做颠什么
    // 监听401 token失效
    
    if(error.response){
        if(error.response.status == 404){
            notification.error({
                message:"请求错误",
                description:"Status:404,正在请求不存在的服务器记录！"
            });
        }else if(error.response.status == 500){
            notification.error({
                message:"请求错误",
                description:error.response.data.msg || "Status:500,服务器发生错误"
            });
        }else if(error.response.status == 401 ){
            notification.error({
                message:"请求错误",
                description:error.response.data.msg || "Status:500,服务器发生错误"
            });
            removeToken()
            router.navigate('/login')
        } else {
            notification.error({
                message:"请求错误",
                description: error.message || `Status:${error.response.status}，未知错误！`
            });
        }
    } else {
        notification.error({
            message:"请求错误",
            description: "请求服务器无响应！"
        });
    }

    return Promise.reject(error)
})

var http = {
    /** get 请求
	 * @param  {string} url 接口地址
	 * @param  {object} params 请求参数
	 * @param  {object} config 参数
	 */
	get: function(url, params={}, config={}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'get',
				url: url,
				params: params,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** post 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	post: function(url, data={}, config={}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'post',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** put 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	put: function(url, data={}, config={}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'put',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** patch 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	patch: function(url, data={}, config={}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'patch',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** delete 请求
	 * @param  {string} url 接口地址
	 * @param  {object} data 请求参数
	 * @param  {object} config 参数
	 */
	delete: function(url, data={}, config={}) {
		return new Promise((resolve, reject) => {
			axios({
				method: 'delete',
				url: url,
				data: data,
				...config
			}).then((response) => {
				resolve(response.data);
			}).catch((error) => {
				reject(error);
			})
		})
	},

	/** jsonp 请求
	 * @param  {string} url 接口地址
	 * @param  {string} name JSONP回调函数名称
	 */
	jsonp: function(url, name='jsonp'){
		return new Promise((resolve) => {
			var script = document.createElement('script')
			var _id = `jsonp${Math.ceil(Math.random() * 1000000)}`
			script.id = _id
			script.type = 'text/javascript'
			script.src = url
			window[name] =(response) => {
				resolve(response)
				document.getElementsByTagName('head')[0].removeChild(script)
				try {
					delete window[name];
				}catch(e){
					window[name] = undefined;
				}
			}
			document.getElementsByTagName('head')[0].appendChild(script)
		})
	}
}

export default http;