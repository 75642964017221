import { Button, Result } from 'antd';
import { useNavigate } from 'react-router-dom'
const NoLogin = ()=>{
    const navigate = useNavigate();
    return(
        <Result
            status="403"
            title="403"
            subTitle="抱歉，尚未登录您无权访问此页面。"
            extra={<Button type="primary" onClick={()=>navigate('/')}>回到首页</Button>}
        />
    )
}
export default NoLogin