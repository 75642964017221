import Article from '@/pages/Article';
import  Layout  from '@/pages/Layout';
import Board from '@/pages/Board';
import About from '@/pages/About';
import NotFound from '@/pages/NotFound';
import Order from '@/pages/Order';
import Invite from '@/pages/Invite';
import Notic from '@/pages/Notic';
import Center from '@/pages/Center';
import Detail from '@/pages/Order/Mpage/Detail';
import Protocol from '@/pages/Protocol';
import ReceiveList from '@/pages/ReceiveList';
import Shop from '@/pages/Shop';

import { createHashRouter, createBrowserRouter } from 'react-router-dom'

import { AuthRoute } from '@/components/AuthRoute'

const router = createBrowserRouter([
    {
        path:'/',
        element:<AuthRoute><Layout><Board /></Layout></AuthRoute>,
        title:'首页'
    },{
        path:'/order',
        element:<AuthRoute><Layout><Order /></Layout></AuthRoute>,
        title:'我的订单'
    },{
        path:'/invite',
        element:<AuthRoute><Layout><Invite /></Layout></AuthRoute>,
        title:'邀请奖励'
    },{
        path:'/about',
        element:<Layout><About /></Layout>,
        title:'关于我们'
    },{
        path:'/notic',
        element:<Layout><Notic /></Layout> ,
        title:'通知公告'
    },{
        path:'/receivelist',
        element:<Layout><ReceiveList /></Layout> ,
        title:'回收列表'
    },{
        path:'/shops',
        element:<Layout><Shop /></Layout> ,
        title:'商家店铺'
    },{
        path:'/center',
        element:<AuthRoute><Layout><Center /></Layout></AuthRoute>,
        title:'个人中心'
    },
    {
        path:"/article/:id",
        element:<Layout><Article /></Layout>,
        title:'详情'
    },
    {
        path:"/protocol/:tag",
        element:<Layout><Protocol /></Layout> ,
        title:'协议'
    },
    {
        path:"/order/detail/:id",
        element:<AuthRoute><Layout><Detail /></Layout></AuthRoute>,
        title:'订单详情'
    },
    {
        path:'*',
        element:<Layout><NotFound /></Layout>,
        title:'页面找不到啦~~'
    }
])

export default router