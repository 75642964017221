import './index.scss'
import {  Tour,Button, Form,Input,message,Space  } from 'antd';
import { useEffect, useRef, useState } from 'react';
import { useDispatch,useSelector } from 'react-redux';
import Dialog from '@/components/Dialog'
import api from '@/api'
import { fetchUserInfo } from '@/store/modules/user'
import { tool,getGuide,setGuide } from "@/utils"
import VerifySlideFixed from "@/components/Verify/verifySlideFixed";

const UserInfo = ()=>{
    const [open, setOpen] = useState(false);
    const ref1 = useRef(null);
    const ref2 = useRef(null);
    
    const steps = [
        {
          title: '第一步',
          description: '点击实名认证',
          target: () => ref1.current,
        },
        {
          title: '第二步',
          description: '复制专属交易链接至第三方平台',
          target: () => ref2.current,
        }
    ];

    const onFinishTour= ()=>{
        setGuide(true)
        api.user.setInit.get()
    }

    const [ form ] = Form.useForm();
    
    const [tradeUrl, setTradeUrl] = useState("");
    const dispatch = useDispatch()
    const [time,setTime] = useState(60)
    const [flag,setFlag] = useState(false)
    const [slideShow,setSlideShow] = useState(false)
    const [countDown,setCountDown] = useState(0)
    const userInfo = useSelector(state=>state.user.userInfo)
    
    useEffect(()=>{
        setTradeUrlInfo();
        let guide = getGuide()
        if(guide == 'false'){
            setOpen(true)
        }else{
            setOpen(false)
        }
    },[])
    
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [isEditName, setIsEditName] = useState(false);
    const [isEditPhone, setIsEditPhone] = useState(false);

    const showModal = () => {
      setIsModalOpen(true);
    };
    const editName = ()=>{
        setIsEditName(true)
    }
    const editPhone = ()=>{
        setIsEditPhone(true)
    }

    const operDialogFunc = (flag)=>{
        setIsModalOpen(flag)
    }
    const operNameFunc = (flag)=>{
        setIsEditName(flag)
    }
    const operPhoneFunc = (flag)=>{
        setIsEditPhone(flag)
    }

    const onFinish = (values) => {
        api.user.real.post(values).then(res=>{
            if(res.code == 0){
                message.success("认证成功");
                setIsModalOpen(false)
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };
    const onNameFinish = (values) => {
        if(values.newNickname.length<1 || values.newNickname.length>12){
            message.error("请输入1-12个字符")
            return ;
        }

        api.user.saveName.post(values).then(res=>{
            if(res.code == 0){
                message.success("修改成功");
                setIsEditName(false)
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };
    const onPhoneFinish = (values) => {
        api.user.real.post(values).then(res=>{
            if(res.code == 0){
                message.success("修改成功");
                setIsEditPhone(false)
                dispatch(fetchUserInfo())
            }else{
                message.error(res.msg)
            }
        })
    };
    

    const apply = ()=>{
        api.user.apply.get().then(res=>{
            if(res.code == 0){
                message.success('申请成功')
                setTradeUrlInfo();
            }else{
                message.error(res.msg)
            }
        })
    }  
  
    const setTradeUrlInfo = ()=>{
        api.user.getTradeUrl.get().then(res=>{
            if(res.code == 0){
                setTradeUrl(res.data.tradeUrl)
                let nowTime = Date.parse(new Date())
                let endTime = Date.parse(res.data.expireTime)
                let time = (endTime - nowTime)/1000
                setCountDown(time)
                const inter = setInterval(()=>{
                    if(time<=0){
                        clearInterval(inter)
                        setTradeUrlInfo()
                    }else{
                        time--;
                        setCountDown(time)
                    }
                },1000)

            }else{
                setTradeUrl("")
                setCountDown(0)
            }
        })
    }
    
    const verifyPointFixedChild = (data)=>{
        setSlideShow(data)
    }

    const sendSms = ()=>{
        if(flag) return;
        var value = form.getFieldValue()
        if(!value.phone){
            message.error('请输入正确的手机号')
            return;
        }
        if(value.phone.length !=11){
            message.error('请输入正确的手机号')
            return;
        }
        setFlag(true)
        setSlideShow(true)
    }
    const onsuccess =(data)=>{
        setSlideShow(false)
        var data= {
            phone:form.getFieldValue().phone,
            codeType:'login',
            captchaVerification:data.captchaVerification
        }
        api.common.sendSms.post(data).then(res=>{
            if(res.code == 0){
                message.success('短信已发送，请注意查收！')
                const inter = setInterval(()=>{
                    if(time<=0){
                        setTime(60)
                        clearInterval(inter)
                        setFlag(false)
                    }else{
                        let tiem = time - 1;
                        setTime(tiem)
                    }
                },1000)
            }else{
                message.error(res.msg)
                setFlag(false)
            }
        })
    }

    const timeFormat = (time)=>{

        let hour = parseInt(time/60/60) 
        let min = parseInt(time/60%60)
        let sec = parseInt(time%60)

        return (hour>=10? hour:'0'+hour) + ':' + (min>=10? min:'0'+min) + ':' + (sec>=10? sec:'0'+sec)
    }

    return(
        <div className='userinfo-wrap'>
            <div className="balance-wrap">
                <i className="balance"></i>
                <p className="money">￥{ userInfo.availableBalance }</p>
                <p>钱包余额</p>
            </div>
            <div className="userinfo">
                <div className='info-item'>
                    <label>我的昵称</label>
                    <div className='info-content'>
                        <span className='text'>{ userInfo.nickname }</span>
                        <span className='btn' onClick={ editName }><i className='edit'></i>修改</span>
                    </div>
                </div>
                <div className='info-item'>
                    <label>用户ID</label>
                    <div className='info-content'>
                        <span className='text'>{ userInfo.userId }</span>
                    </div>
                </div>
                <div className='info-item'>
                    <label>手机号码</label>
                    <div className='info-content'>
                        <span className='text'>{ userInfo.phone }</span>
                        {/* <span className='btn' onClick={ editPhone }><i className='edit'></i>修改</span> */}
                    </div>
                </div>
                <div className='info-item'>
                    <label>注册时间</label>
                    <div className='info-content'>
                        <span className='text'>{ userInfo.registerAt }</span>
                    </div>
                </div>
                <div className='info-item'>
                    <label>邀请码</label>
                    <div className='info-content'>
                        <span className='text'>{ userInfo.promoteCode }</span>
                    </div>
                </div>
                <div className='info-item'>
                    <label>实名认证</label>
                    <div className='info-content'>
                        { userInfo.isRealName?<span className='text'>已实名</span>:<span className='text'>未实名</span> } 
                        { !userInfo.isRealName && <span className='btn' ref={ref1} onClick={showModal}><i className='copy'></i>认证</span>}
                    </div>
                </div>
                <div className='info-item'>
                    <label>专属交易链接</label>
                    <div className='info-content' style={{width:'542px'}}>
                        <span className='text'>{ tradeUrl?tradeUrl:'--' }</span>
                        {tradeUrl? <span className='btn' ref={ref2} onClick={() => tool.copy(tradeUrl)}><i className='copy'></i>复制</span> : <span className='btn' ref={ref2} onClick={ apply }><i className='copy'></i>申请</span>}  
                    </div>
                </div>
                <div className='info-item'>
                    <label>专属链接有效期</label>
                    <div className='info-content'>
                        { timeFormat(countDown) }
                    </div>
                </div>
            </div>
            <Tour open={open} onClose={() => setOpen(false)} steps={steps} onFinish={ onFinishTour } nextButtonProps='下一步' />
            <Dialog 
                title="实名认证" 
                footer = {<></>}
                vis={isModalOpen} 
                operDialogFunc={operDialogFunc}>
                <Form name="realForm"  autoComplete="off" onFinish={ onFinish }>
                    <Form.Item label="真实姓名" name="idcName">
                        <Input placeholder='请输入真实姓名'/>
                    </Form.Item>
                    <Form.Item label="身份证号" name="idcNo">
                        <Input placeholder='请输入身份证号码'/>
                    </Form.Item>
                    <div className="footer">
                        <Button type='primary' htmlType='submit' className='button'>立即实名</Button>
                    </div>
                </Form>
            </Dialog>

            <Dialog 
                title="修改用户名" 
                footer = {<></>}
                vis={isEditName} 
                operDialogFunc={operNameFunc}>
                <Form name="realForm"  autoComplete="off" onFinish={ onNameFinish }>
                    <Form.Item label="用户名" name="newNickname">
                        <Input defaultValue={ userInfo.nickname } placeholder="请输入1-12个字符"/>
                    </Form.Item>
                    <div className="footer">
                        <Button type='primary' htmlType='submit' className='button'>立即修改</Button>
                    </div>
                </Form>
            </Dialog>

            <Dialog 
                title="修改手机号" 
                footer = {<></>}
                vis={isEditPhone} 
                operDialogFunc={operPhoneFunc}>
                <Form name="realForm"  autoComplete="off" onFinish={ onPhoneFinish } form = { form }>
                    <Form.Item label="手机号" name="phone">
                        <Input placeholder='请输入新手机号'/>
                    </Form.Item>
                    <Form.Item label="验证码" name="idcNo">
                        <Space.Compact >
                            <Input placeholder="请输入验证码"/>
                            <Button type="link" onClick={sendSms} disabled={flag}> {flag && (<> {time}s </>)}  获取验证码  </Button>
                            <VerifySlideFixed isSlideShow={slideShow} verifyPointFixedChild={verifyPointFixedChild}  verifyPointFixedSuccess ={ onsuccess }/> 
                        </Space.Compact>
                    </Form.Item>
                    <div className="footer">
                        <Button type='primary' htmlType='submit' className='button'>立即修改</Button>
                    </div>
                </Form>
            </Dialog>

        </div>
    )
}

export default UserInfo