import { useEffect,useState } from "react"
import Dialog from "../Dialog"
import LoginBySms from "./components/LoginBySms";
import LoginByPwd from "./components/LoginByPwd";
import Register from "./components/Register";
import Forget from "./components/Forget";


const Login = (props)=>{
    const { vis } = props
    const [visiable,setVisiable]=useState(false)
    const [formType,setFormType] = useState('SMS')

    function operDialogFunc(flag){
        props.operDialogFunc(flag)
    }

    function close (){
        operDialogFunc(false)
    }

    function getForm(){
        switch(formType){
            case 'SMS':
                return <LoginBySms changeType={changeType} close={close} />
            case 'PWD':
                return <LoginByPwd changeType={changeType} close={close}/>
            case 'Forget':
                return <Forget changeType={changeType}/>
            case 'Register':
                return <Register changeType={changeType}/>
        }
    }

    function changeType(type){
        setFormType(type)
    }

    useEffect(()=>{
        setVisiable(vis)
    })

    const title = ()=>{
        var title_txt = ''
        var span = ''
        if(formType == 'SMS' || formType == 'PWD'){
            title_txt = '登录账号'
            span = <span className='btn' onClick={()=>changeType('Register')}>注册</span> 
        }else if(formType == 'Register'){
            title_txt = '注册账号'
            span = <span className='btn' onClick={()=>changeType('SMS')}>登录</span>
        }else if(formType == 'Forget'){
            title_txt = '忘记密码'
            span = <span className='btn' onClick={()=>changeType('SMS')}>返回登录</span> 
        }
        return(
            <>
                <div className='title'> 
                    <span className='title'>{ title_txt }</span>
                    { span }
                </div>
            </>
        )
    }

    return(
        <Dialog 
           title={ title() } 
           footer = {<></>}
           vis={visiable} 
           operDialogFunc={operDialogFunc}>
            { getForm() }
        </Dialog>
    )
}
export default Login