import './index.scss'
import { Button,Drawer,Pagination,message   } from 'antd'
import { useRef, useState } from 'react';
import api from '@/api'
import {tool} from '@/utils'


const Mpage = (props)=>{
    const { list,total } = props
    const [open, setOpen] = useState(false);

    const [weapon,setWeapon] = useState([])
    const [weponTotal,setWeaponTotal] = useState(0)
    const storeIdRef = useRef(0)

    const showDrawer = (id) => {
      setOpen(true);
      storeIdRef.current = id
      getData(1)
    };
    const onClose = () => {
      setOpen(false);
    };

    const apply=(id)=>{
        api.user.apply.get(id).then(res=>{
            if(res.code == 0){
                tool.copy(res.data.tradeUrl)
                message.success('交易链接已复制，请前往个人中心查看链接有效期。')
            }else{
                message.error(res.msg)
            }
        })
    }

    const getData=(page)=>{
        let params = {
            pageNum:page,
            pageSize:9,
            storeId:storeIdRef.current
        }
        api.shop.detail.post(params).then(res=>{ 
            if(res.code == 0){
                setWeapon(res.data)
                setWeaponTotal(res.totalCount)
            }
        })
    }

    return(
        <>
            <div className='shops-wrap'>
                {
                    list.map((item)=>(
                        <div className='shop' key={item.id}>
                            <div className='top-info'>
                                <div className='image-wrap'>
                                    <img src={item.icon}></img>
                                </div>
                                <div className='shop-info'>
                                    <h3>{item.name}</h3>
                                    <p className='desc'>店铺描述：{item.description}</p>
                                </div>
                            </div>
                            <p className='order-info'><span>回收折扣：{ item.discount*100 }%</span> <span>累计回收笔数：{ item.orderCount }</span> </p>
                            <div className='weapon-list'>
                                {
                                    item.itemList.map((good)=>(
                                        <div className='weapon' key={good.itemName}>
                                            <div className='image-wrap'>
                                                <img src={good.itemImg}></img>
                                            </div>
                                            <div className='weapon-info'>
                                                <p className='name'>{good.itemName}</p>
                                                <p className='price'>￥{good.price}</p>
                                            </div>
                                        </div>
                                    ))
                                }
                                
                            </div>
                            <div className='bottom'>
                                <div className='btn' onClick={()=>apply(item.id)}>
                                    <i className='link'></i>
                                    申请专属交易链接
                                </div>
                                <Button onClick={()=>showDrawer(item.id)}>查看详情</Button>
                            </div>
                        </div>
                    ))
                }
                
            </div>
            <Drawer title="回收饰品" onClose={onClose} open={open} placement='bottom' height="60vh">
                <div className='goodslist'>
                    {
                        weapon.map((item)=>(
                            <div className='weapon' key={item}>
                                <div className='image-wrap'>
                                    <img src={item.itemImg}></img>
                                </div>
                                <div className='weapon-info'>
                                    <p className='name'>{ item.itemName }</p>
                                    <p className='price'>￥{ item.price }</p>
                                </div>
                            </div>
                        ))
                    }
                    
                </div>
                <div className='page-wrap'>
                    <Pagination defaultCurrent={1} total={weponTotal} pageSize={9} onChange={getData} />
                </div>
            </Drawer>
        </>
    )
}

export default Mpage;