import config from "@/config"
import http from "@/utils/request"

export default{
    captcha:{
        get:{
            url:`${config.API_URL}/captcha/get`,
            name:"获取行为校验信息",
            post: async function(data,config={}){
                return await http.post(this.url,data,config)
            }
        },
        check:{
            url:`${config.API_URL}/captcha/check`,
            name:"进行行为校验",
            post: async function(data,config={}){
                return await http.post(this.url,data,config)
            }
        }
    },
    sendSms:{
        url:`${config.API_URL}/system/sendMessage`,
        name:"发送验证码短信",
        post: async function(data,config={}){
            return await http.post(this.url,data,config)
        }
    }
}