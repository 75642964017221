import { useEffect, useState } from 'react'
import './index.scss'
import api from '@/api'
import { Pagination } from 'antd'
import { useNavigate } from 'react-router-dom'

const Notic =()=>{
    const [list,setList] = useState([])
    const [total,setTotal] = useState([])
    const navigate = useNavigate();
    const pageSize = 20;
    useEffect(()=>{
        getData(1)
    },[])

    async function getData(page){
        let data={
            pageNum:page,
            pageSize:pageSize,
            docCateTag:'xtgg'
        }
        let res = await api.board.content.list.post(data)
        if(res.code == 0){
            setList(res.data)
            setTotal(res.totalCount)
        }
    }

    return(
        <div className='notic-wrap-web'>  
            { list.map((item,index)=>(
                <div className="noitc-item" key={item.id}>
                    <h3>{index+1} 、 {item.docDescribe}</h3>
                    <div onClick={ ()=>navigate('/article/'+item.id)}>
                        <i className="da"></i>
                        <div dangerouslySetInnerHTML={{ __html:  item.docContent }}></div>
                    </div>
                </div>
            ))}
            { total>pageSize &&
                <Pagination defaultCurrent={1} pageSize={pageSize} total={total} onChange={ getData }/> 
            }
        </div>
    )
}

export default Notic