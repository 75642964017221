import { useEffect, useState } from "react"
import { useParams } from "react-router-dom"
import api from '@/api'

const Protocol = ()=>{
    const params =  useParams()
    const tag = params.tag
    const [content,setContent] = useState({});

    useEffect(()=>{
        let data = {
            pageNum:1,
            pageSize:1,
            docCateTag:tag
        }
        api.board.content.list.post(data).then(res=>{
            if(res.code == 0){
                setContent(res.data[0])
            }
        })
    },[])

    return(
        <>
            <div style={{padding:'12px'}} dangerouslySetInnerHTML={{ __html: content.docContent }}></div>
        </>
    )
}

export default Protocol;