import Mpage from './components/Mpage'
import Wpage from './components/Wpage';
import api from '@/api'
import { useEffect, useState } from 'react';

const Shop = ()=>{
    const [list,setList] = useState([])
    const [total,setTotal] = useState(0)

    const getData = (page)=>{
        let params = {
            pageNum:page,
            pageSize:20
        }

        api.shop.list.post(params).then(res=>{
            if(res.code == 0){
                setList(res.data)
                setTotal(res.totalCount)
            }
        })
    }

    useEffect(()=>{
        getData(1)
    },[])

    return(
        <>
            {window.innerWidth>790 && <Wpage list={list} total={total}></Wpage>}
            {window.innerWidth<=790 && <Mpage list={list} total={total}></Mpage>}
        </>
    )
}

export default Shop;

