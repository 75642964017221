import "./index.css"
import TopBar from './components/TopBar'
import MyFooter from './components/Footer'
import MTopBar from './components/MTopBar'
import { fetchSiteInfo } from '@/store/modules/site'
import { useDispatch, useSelector } from 'react-redux'
import { Popover, QRCode  } from 'antd';
// 测试token 是否成功注入

import { useEffect,useState } from 'react'

const Layout = (props) => {
    const { children } = props
    const [screenWidth,setScreenWidth] = useState();
    const dispatch = useDispatch()

    useEffect(()=>{
        const updateScreenSize = ()=>{
            setScreenWidth(window.innerWidth)
        }

        window.addEventListener('resize',updateScreenSize);
        updateScreenSize();

        return ()=> window.removeEventListener('resize',updateScreenSize)
    },[])

    
    useEffect(()=>{
        dispatch(fetchSiteInfo())
    },[])


    const getTop = ()=>{
        if(screenWidth <= 790){
            return <MTopBar />
        }else{
            return <TopBar />
        }
    }
    const siteInfo = useSelector(state=>state.site.siteInfo)

    const contact = ()=>{
        window.location.href = siteInfo.wechat;
    }

    return(
        <>  
            {getTop()}
            <div className='main-content'>
                <div className='content'>
                    {children}
                    <Popover placement="left"  content={ <QRCode value={siteInfo.wechat} color='#F0F0F0'/>}>
                        <div className='tools' onClick={ contact }>
                            <i className='contact'></i>
                            {screenWidth <= 790?<span>客服</span>:<span>联系客服</span>}
                        </div>
                    </Popover>
                </div>
            </div>
            <MyFooter />
        </>
        
    )
}

export default Layout