// 和网站相关的状态管理

import { createSlice } from "@reduxjs/toolkit"
import api from '@/api'

const siteStore = createSlice({
    name:"site",
    // 数据状态
    initialState:{
        siteInfo:{}
    },
    // 同步修改方法
    reducers:{
        setSiteInfo(state,action){
            state.siteInfo = action.payload
        }
    }
})

// 解构出actionCreater

const { setSiteInfo } = siteStore.actions

// 获取reducer函数

const siteReducer = siteStore.reducer

// 获取个人信息
const fetchSiteInfo = ()=>{
    return async (dispatch) =>{
      const res = await api.board.website.get()
      if(res.code == 0){
        dispatch(setSiteInfo(res.data))
      }
    }
}

export { fetchSiteInfo }

export default siteReducer